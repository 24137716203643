import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, delay, first, retry, timeout } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firstValueFrom, of } from 'rxjs';
import { PopupService } from '../../shared/services/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  userData: any;
  credential: any;
  
  constructor(
    public router: Router,  
    public ngZone: NgZone,
    public http: HttpClient,
    private ngFireAuth: AngularFireAuth,
    private popupService: PopupService,
    private translate: TranslateService
  ) {

  }

  getV2CToken(): Promise<string> {
    console.log("GETV2CTOKEN");
    return new Promise(async (resolve) => {
      try {
        console.log("ENTRAMOS A GETV2CTOKEN");
        const oauth = "Basic " + environment.v2cServiceConfig.basicOAuth;
        const header = new HttpHeaders().set("Authorization", oauth);
        const requestOptions = { headers: header };
        let user = JSON.parse(localStorage.getItem("user") || '{}');
        
        if (localStorage.getItem("clearItem") === "si") {
          console.log("Pedimos user de nuevo al refrescar el token");
          user = await this.getUser();
        } else {
          user = JSON.parse(localStorage.getItem("user") || '{}');
        }
        
        // user = JSON.parse(localStorage.getItem("user") || '{}');
        console.log(user);

        // Verificamos si el token está en `user.stsTokenManager` o en `user.multiFactor.user.stsTokenManager`
        let tokenFirebase: string | undefined;
        
        if (user.stsTokenManager && user.stsTokenManager.accessToken) {
          // Si el token está en la ruta original
          console.log("Ruta original");
          tokenFirebase = user.stsTokenManager.accessToken;
        } else if (user.multiFactor && user.multiFactor.user && user.multiFactor.user.stsTokenManager) {
          // Si el token está en la ruta anidada dentro de multiFactor
          console.log("Ruta anidada cuando caduca token firebase");
          tokenFirebase = user.multiFactor.user.stsTokenManager.accessToken;
        }

        // Si no encontramos el token, lanzamos un error o resolvemos con una cadena vacía
        if (!tokenFirebase) {
          console.log("No se pudo encontrar el token Firebase");
          return resolve("");
        }

        const params = new HttpParams()
          .set("grant_type", "firebase")
          .set("firebase_token_id", tokenFirebase);
        
        const response: any = await firstValueFrom(
          this.http.post(
            environment.v2cServiceConfig.basePath + "/oauth/token",
            params,
            requestOptions
          )
        );

        resolve(response.access_token);

      } catch (error) {
        console.log("ERROR DE AUTENTICACION AL OBTENER TOKEN");
        console.log(error);

        if (error.status === 0) {
          console.log("Fallo del servidor");
          localStorage.setItem("FalloServidor", "si");
        } else if (error.status === 401 && localStorage.getItem("FalloServidor") === "si") {
          console.log("Servidor ya armado, volvemos a pedir user una vez");
          await this.getUser();
          const token = await this.getV2CToken();
          resolve(token);
          localStorage.removeItem("FalloServidor");
          return;
        } else if (error.status === 401 && localStorage.getItem("FalloServidor") === null) {
          console.log("Refrescamos user guardado en caché");
          localStorage.setItem("FalloServidor", "no");
          await this.getUser();
          const token = await this.getV2CToken();
          resolve(token);
          return;
        } else {
          localStorage.removeItem("FalloServidor");
        }
        resolve("");
      }
    });
  }

  async getUser() {
    console.log("GET USER");
    
    try {
      const user = await firstValueFrom(
        this.ngFireAuth.authState.pipe(
          first(),
          timeout(4000),
          retry({
            count: 3, // Reintenta hasta 3 veces
            delay: (error, retryCount) => {
              console.log(`Retry attempt #${retryCount}`);
              return of(error).pipe(delay(1000 * retryCount)); // Backoff exponencial
            }
          }),
          catchError(error => {
            console.error("El metodo GETUSER ha fallado, el internet no funciona bien", error);
            return of(null); // Retorna null si hay un error
          })
        )
      );
  
      console.log("EL METODO HA FUNCIONADO VEMOS EL USER", user);
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
  
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
      return null;
    }
  }

  getAuth() {
    return getAuth();
  }

  // Login in with email/password
  SignIn(email: string, password: string) {
    return signInWithEmailAndPassword(getAuth(), email, password);
  }

  // Register user with email/password
  RegisterUser(email: string, password: string) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  // Email verification when new user register
  SendVerificationMail() {
    this.ngFireAuth.useDeviceLanguage();
    return this.ngFireAuth.currentUser
    .then((u) => u.sendEmailVerification())
    .then(() => {
      this.router.navigate(["/login"]);
    })
  }

  // Recover password
  async PasswordRecoverLogin(passwordResetEmail: any) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      const popupData = {
        type: 1,
        message: this.translate.instant("AlertMsgCambioContrasenya")
      };
      this.popupService.openPopup(popupData);
    }).catch((error) => {
      const popupData2 = {
        type: 1,
        message: this.translate.instant("AlertMsgEmailNoRegistrado")
      };
      this.popupService.openPopup(popupData2);
    })
  }

  // Recover password
  PasswordRecover(passwordResetEmail: any) {
    return new Promise(resolve => this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
      resolve(true);
    }).catch(() => {
      resolve(false);
    }));
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return (user.emailVerified !== false) ? true : false;
  }

  GetUsername() {
    console.log("GETUSERNAME");
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    if (user.displayName && user.displayName != null)
      return user.displayName;
    else
      return user.email.split("@")[0];
  }

  GetMailAddress() {
    console.log("GETMAILADDRESS");
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    return user.email;
  }

  async getRequestOptions() {
    console.log("GetRequestOptions");
    var token = await this.getV2CToken();
    var oauth = "Bearer " + token;
    const header = new HttpHeaders().set("Authorization", oauth);
    return { headers: header };
  }

  // Sign in with Gmail
  GoogleAuth() {
    console.log("Login google");
    //return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth providers
  AuthLogin(provider: any) {

  }

  // Store user in localStorage
  SetUserData(user: any) {

  }

  // Sign-out 
  SignOut() {
    console.log("Limpiar token");
    // Limpiar el token almacenado en el interceptor
    localStorage.setItem("clearItem", "si");
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem("user");
      sessionStorage.removeItem("pairings");
      sessionStorage.removeItem("selectedTags");
      this.router.navigate(["/login"]);
    });
  }

  async DeleteUser() {
  }

  refreshToken() {
    return this.getUser();
  }
}