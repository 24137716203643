import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HttpClientModule, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  @ViewChild('dialogNewWeb') dialogNewWeb!: ElementRef<HTMLDialogElement>;
  subscription!: Subscription;
  title = 'V2C Cloud';
  runUpdateTokenTimeAsync: any;
  
  constructor(private translate: TranslateService, 
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2
  ) {
    localStorage.setItem("clearItem", "si");
    this.bucleActualizarToken(60);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showWelcomeModal();
    }, 1000);
    this.subscription = this.router.events.pipe(filter(event => 
      event instanceof NavigationEnd
    )).subscribe(() => 
      window.scrollTo(0, 0)
    );
    this.setWebLanguage();
    this.authService.checkAuthentication();
  }

  showWelcomeModal() {
    // Verifica si el usuario ya ha visto el mensaje de bienvenida
    const hasVisited = localStorage.getItem('hasVisited');
    
    if (!hasVisited) {
      // Muestra el modal
      this.openModalNewWeb();

      // Guarda la bandera en localStorage para futuras visitas no mostrarlo mas
      localStorage.setItem('hasVisited', 'true');
    }
  }

  setWebLanguage() {
    const localStorage = document.defaultView?.localStorage;
    // Modify on new language
    const languages = ['es', 'en', 'it', 'pt', 'fr', 'de', 'eu'];
    var useBr = true;
    if (localStorage) {
      var lnLoc = localStorage.getItem("Language");
      if (lnLoc) {
        useBr = false;
        this.renderer.setAttribute(document.documentElement, 'lang', lnLoc);
        this.translate.setDefaultLang(lnLoc);
        this.translate.use(lnLoc);
      }
    }

    if (useBr) {
      var lnBr = this.translate.getBrowserLang();
      if (lnBr) {
        lnBr = lnBr.substring(0, 2);
        if (!languages.includes(lnBr)) {
          lnBr = 'en';
        }
      } else {
        lnBr = 'en';
      }

      this.renderer.setAttribute(document.documentElement, 'lang', lnBr);
      this.translate.setDefaultLang(lnBr);
      this.translate.use(lnBr);
      if (localStorage) {
        localStorage.setItem("Language", lnBr);
      }
    }
  }

  bucleActualizarToken(time) {
    this.runUpdateTokenTimeAsync = () => {
      console.log("ACTUALIZAMOS EL TOKEN"); // Actualizar token si el usuario se mantiene en la app más de un minuto
      localStorage.setItem("clearItem", "si");
    };
  
    // Ejecutar la función runUpdateTokenTimeAsync cada dos minutos y medio
    setInterval(this.runUpdateTokenTimeAsync, time * 2500);
  }

  closeModalNewWeb() {
    this.dialogNewWeb.nativeElement.close();
    this.dialogNewWeb.nativeElement.classList.remove('opened');
  }

  openModalNewWeb() {
    this.dialogNewWeb.nativeElement.showModal();
    this.dialogNewWeb.nativeElement.classList.add('opened');
  }

}
